<template>
  <router-link :to="link" class="btn btn-ghost btn-sm rounded-btn">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "NavLink",
  props: ['link'],
};
</script>
