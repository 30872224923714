<template>
  <footer class="footer bg-white px-6 py-5">
    <span class="text-md font-bold">
      Business Tech Services, LLC
    </span>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>
